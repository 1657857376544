import React, { memo } from "react"
import { Animated } from "react-animated-css"
import { Typography } from "antd"

import OrrProtection from "../images/orrprotection.svg"
import HP from "../images/hp.svg"
import CandB from "../images/candb.svg"
import izharSteel from "../images/izhar-steel.svg"
import counterState from '../services/counterAtom'
import { useRecoilState } from 'recoil'

const TrustedBy = ({ showHeading = true, justifyStart }) => {
  const { Title } = Typography
  const [counter, setCounter] = useRecoilState(counterState);

  return (
    <div className="trusted-by">
      <div
        className={`trusted-by-inner-container ${justifyStart ? "justify-start" : ""}`}
      >
        {showHeading && <Title level={3} style={{ color: counter % 2 !== 0 ? "black" : "white" }} >Trusted By</Title>}
        <Animated
          animationIn="fadeIn"
          animationInDuration={800}
          animationInDelay={3000}
          isVisible={true}
          className="trusted-by-client-image-wrapper bg-white"
        >
          <img src={OrrProtection} alt="OrrProtection" />
        </Animated>
        <Animated
          animationIn="fadeIn"
          animationInDuration={800}
          animationInDelay={3300}
          isVisible={true}
          className="trusted-by-client-image-wrapper bg-white"
        >
          <img src={HP} alt="Hush Puppies" />
        </Animated>
        <Animated
          animationIn="fadeIn"
          animationInDuration={800}
          animationInDelay={3600}
          isVisible={true}
          className="trusted-by-client-image-wrapper bg-candb"
        >
          <img src={CandB} alt="Cakes Bakes" />
        </Animated>
        <Animated
          animationIn="fadeIn"
          animationInDuration={800}
          animationInDelay={3900}
          isVisible={true}
          className="trusted-by-client-image-wrapper bg-white"
        >
          <img src={izharSteel} alt="Izhar Steel" />
        </Animated>
      </div>
    </div>
  )
}

export default memo(TrustedBy)
