import React from "react"
import { Typography } from "antd"

const { Title } = Typography

const DevelopmentProcess = () => {
  return (
    <div className="vm-development-process">
      <div className="vm-development-process-header-TS">
        <Title>Our Testing Service Process</Title>
        <p>
        Despite various detailed testing service processes observed in the industry, 
        we rather follow a simplistic approach to develop testing service solutions. 
        </p>
      </div>
      <div className="vm-dev-process-col4-body">
        <div>
          <div className="number-and-text-wrapper">
            <Title>01</Title>
            <Title level={4}>Discovery</Title>
          </div>
          <ul>
            <li>Analyzes Project Documentation</li>
            <li>Audits your Existing Testing Process</li>
          </ul>
        </div>
        <div>
          <div className="number-and-text-wrapper">
            <Title>02</Title>
            <Title level={4}>Transition</Title>
          </div>
          <ul>
            <li>Designs a Testing Strategy</li>
            <li>Sets up required tools and takes on the responsibility for the test process</li>
          </ul>
        </div>
        <div>
          <div className="number-and-text-wrapper">
            <Title>03</Title>
            <Title level={4}>Service delivery</Title>
          </div>
          <ul>
            <li>Test Planning</li>
            <li>Test Design</li>
            <li>Test Execution</li>
            <li>Test Reporting</li>
          </ul>
        </div>
        <div>
          <div className="number-and-text-wrapper">
            <Title>04</Title>
            <Title level={4}>Service evolution</Title>
          </div>
          <ul>
            <li>continuously review and improve the established testing processes</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentProcess
