import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import DevelopmentProcess from "../../components/TS_DevelopmentProcess"
import SideForm from "./../../components/SideForm"

import "../../styles/services.scss"
import "../../styles/animate.css"

const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Quality Assurance Services"}
    descrip = {"Ensure flawless software with our comprehensive quality assurance services. Our expert QA team delivers reliable testing and bug-free solutions. Learn more!"}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/" >
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>

        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>We Offer Manual and Automated Testing Services</Title>
              <p>
                We provide effective Manual and automated testing solutions,
                which help our customers to make their software reliable. <br />
                <br />
                We offer a wide range of testing activities from test planning,
                test cases’ design and execution, to test reporting and QA
                process improvements.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="heading-and-detail">
        <Title>Manual and Automated Testing Services</Title>
        <p>
          Visiomate provides API and UI functional, integration, compatibility,
          performance, usability, and security managed testing services to
          ensure the high quality of your software within optimal testing time
          and budget.
        </p>
      </div>
      <div className="cards-wrapper">
        <Card title="UI Functional Testing">
          <p>
            Functional testing is a form of automated testing that deals with
            how applications functions, or, in other words, its relation to the
            users and especially to the rest of the system.
          </p>
        </Card>
        <Card title="Performance Testing">
          <p>
            Performance testing is the practice of evaluating how a system
            performs in terms of responsiveness and stability under a particular
            workload.
          </p>
        </Card>
        <Card title="Integration Testing">
          <p>
            Integration testing is conducted to evaluate the compliance of a
            system or component with specified functional requirements.
          </p>
        </Card>
        <Card title="Interface Testing">
          <p>
            Interface Testing is defined as a software testing type which
            verifies whether the communication between two different software
            systems is done correctly.
          </p>
        </Card>
      </div>
      <DevelopmentProcess />
    </Layout>
  )
}

export default Index

export const softwareTestingQuery = graphql`
  query softwareTestingQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
